import React from 'react'
import { Link } from 'gatsby'
import {
  Wrap,
  FormCopy,
  FormBox,
  FormFields,
  FormField,
  Title,
} from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import SEO from '../components/SEO'

const AccountGoodbye = () => {
  return (
    <Wrap>
      <SEO title="Account Cancelled" />
      <FocusHeader title="Account" backUrl="/" />
      <FocusBody>
        <Title>Account Cancelled</Title>
        <FormBox>
          <FormFields isProcessing={false}>
            <FormField width={`100%`}>
              <FormCopy>
                Your account has now been cancelled. We're sad to see you go!
                <br />
                <br />
                Back to <Link to="/">The Guide</Link>.
              </FormCopy>
            </FormField>
          </FormFields>
        </FormBox>
      </FocusBody>
    </Wrap>
  )
}
export default AccountGoodbye
